import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import {SellerRegistration} from './seller-registration/sellerregistration';
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";
import { LoginObserver } from "./LoginObserver";
// import {Banner} from './banner/banner';
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // baseurl = 'http://213.42.147.171/';
  // baseurl = "http://209.182.237.239:8080/";
  //baseurl = "https://uatapi.yellowpages.ae/";
  baseurl = "https://api.yellowpages.ae/";
  // baseurl = "http://localhost:8080/";
  smsurl =
    "http://mshastra.com/sendurl.aspx?user=20093656&pwd=uurgir&senderid=MBISMS&Countrycode=All";
  // Http Headers

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
    }),
  };

  constructor(private httpClient: HttpClient) { }

  // // POST
  // public SellerRegistration(data): Observable<SellerRegistration> {
  //   // return this.httpClient.post<SellerRegistration>(this.baseurl + 'api/register', JSON.stringify(data), { headers: new HttpHeaders().set('Content-Type', 'application/json')})
  //   console.log(JSON.stringify(data));
  //   return this.httpClient.post<SellerRegistration>(this.baseurl + 'api/register', JSON.stringify(data), { headers: new HttpHeaders().set('Content-Type', 'application/json')})
  //     .pipe(
  //       retry(1)
  //       // catchError(this.handleError)
  //     );
  // }

  // POST
  public loginUser(data): Observable<any> {
    // return this.httpClient.post<SellerRegistration>(this.baseurl + 'api/register', JSON.stringify(data), { headers: new HttpHeaders().set('Content-Type', 'application/json')})
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(this.baseurl + "api/authenticate", JSON.stringify(data), {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public loginPhn(data): Observable<LoginObserver> {
    let val;
    let smstext;
    val = Math.floor(1000 + Math.random() * 9000);
    console.log(val);
    localStorage.setItem("userOtp", val);
    console.log(JSON.stringify(data));
    smstext = "Please use " + val + " as your otp to login with yellowpages";
    return this.httpClient
      .post<LoginObserver>(
        this.smsurl + "&mobileno=" + data + "&msgtext=" + smstext,
        { headers: new HttpHeaders().set("Content-Type", "application/json") }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // POST
  public forgotPassword(data): Observable<any> {
    // return this.httpClient.post<SellerRegistration>(this.baseurl + 'api/register', JSON.stringify(data), { headers: new HttpHeaders().set('Content-Type', 'application/json')})
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(this.baseurl + "api/account/reset-password/init", data, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Requirements
  public postRequirements(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/buyer-requirements",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Requirements
  public manualSalesEnquiry(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/enquiries-manual",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }
  //GET Offers
  public getOffers(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/offers", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Offers
  public getOfferDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/offers/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Offers
  public postOffers(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(this.baseurl + "api/offers", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Banners
  public putOffer(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(this.baseurl + "api/offers", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Offers
  public deleteOffers(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/offers/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Offers
  public getWebPages(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/web-pages", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Offers
  public getWebPageDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/web-pages/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Offers
  public postWebPages(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/web-pages",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Offers
  public putWebPage(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/web-pages",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Web Page
  public deleteWebPage(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/web-pages/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Offers
  public getBanners(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/banners?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getNotifications(authToken, dataTablesParameters): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);

    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/notifications?page=" +
        dataTablesParameters.start +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sendNotification(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/send-notification",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe
      // retry(1)
      // catchError(this.handleError)
      ();
  }

  public deleteEmailTemplate(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<any>(this.baseurl + "api/email-templates/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getEmailTemplates(authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<any>(this.baseurl + "api/email-templates", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postEmailTemplate(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(this.baseurl + "api/email-templates", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public editEmailTemplate(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<any>(this.baseurl + "api/email-templates", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getEmailTemplateDetails(offerId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<any>(this.baseurl + "api/email-templates/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Banners
  public getBannerDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/banners/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getNotifDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/notifications/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Banners
  public postBanner(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(this.baseurl + "api/banners", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Banners
  public putBanner(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(this.baseurl + "api/banners", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Banner
  public deleteBanner(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/banners/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Blogs

  public getBlogs(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/blogs?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc" +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getBlogDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/blogs/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postBlog(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(this.baseurl + "api/blogs", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Blog
  public putBlog(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(this.baseurl + "api/blogs", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Blog
  public deleteBlog(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/blogs/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Claim Business

  public getClaimBusiness(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/claim-businesses" +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getClaimBusinessDetails(
    offerId,
    authToken
  ): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/claim-businesses/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postClaimBusiness(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/claim-businesses",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putClaimBusiness(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/claim-businesses",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteClaimBusiness(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/claim-businesses/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Keywords

  public getAllKeywords(): Observable<any> {
    return this.httpClient
      .get<any>(this.baseurl + "api/keywords?size=" + 10000 + "&sort=title", {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set(
            "Authorization",
            "Bearer " + localStorage.getItem("userAccessToken")
          ),
      })
      .pipe(retry(1));
  }

  public getKeywords(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    let columnOrder = dataTablesParameters.order[0].column;
    let columnDir = dataTablesParameters.order[0].dir;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/keywords" +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=" +
        (columnOrder && columnOrder == 5 ? "keywordResultCount" : "title") +
        "," +
        columnDir +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getKeywordsDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/keywords/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postKeywords(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/keywords",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putKeywords(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(this.baseurl + "api/keywords", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteKeywords(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/keywords/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //blind keywords

  public getAllBlindKeywords(): Observable<any> {
    return this.httpClient
      .get<any>(
        this.baseurl + "api/blind-keywords?size=" + 10000 + "&sort=title",
        {
          headers: new HttpHeaders()
            .set("Content-Type", "application/json")
            .set(
              "Authorization",
              "Bearer " + localStorage.getItem("userAccessToken")
            ),
        }
      )
      .pipe(retry(1));
  }

  public getBlindKeywords(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    let columnOrder = dataTablesParameters.order[0].column;
    let columnDir = dataTablesParameters.order[0].dir;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/blind-keywords" +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (columnOrder && columnOrder == 5
          ? "&sort=keywordResultCount," + columnDir
          : columnOrder && columnOrder == 1
            ? "&sort=title," + columnDir
            : "&sort=createdDate,desc") +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getBlindKeywordsDetails(
    offerId,
    authToken
  ): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/blind-keywords/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putBlindKeywords(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/blind-keywords",
        JSON.stringify(data),
        {
          headers: headersForEnquiriesAPI,
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postBlindKeywords(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/blind-keywords",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteBlindKeywords(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/blind-keywords/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //end blind keywords

  public getBulkBuploads(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/file-uploads" +
        "?sort=uploadedDate,desc&page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sellerFileUpload(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/seller-file-upload",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public productFileUpload(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/product-file-upload",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteNotification(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/notifications/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET SalesOrder
  public getSalesOrders(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/sales-orders?size=20000", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET SalesOrder
  public getSalesOrderDetails(offerId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/sales-orders/" + offerId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST SalesOrder
  public postSalesOrder(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/sales-orders",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST SalesOrder
  public putSalesOrder(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/sales-orders",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete SalesOrder
  public deleteSalesOrder(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/sales-orders/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Requirements
  public updateRequirements(
    data,
    authToken,
    userId
  ): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/buyer-requirements?userId=" + userId,
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST Enquiries
  public postEnquiries(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/enquiries",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updateEnquiries(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .put<any>(this.baseurl + "api/enquiries", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Enquiries
  public getEnquiries(authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<any>(
        this.baseurl + "api/enquiries?size=2000&sort=enquiryDate,desc",
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getEnquiriesHeaderTemp(
    authToken,
    dataTablesParameters
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/enquiries?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public filterGenericEnquiry(
    authToken,
    dataTablesParameters,
    payload
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    if (
      dataTablesParameters &&
      dataTablesParameters.search &&
      dataTablesParameters.search.value &&
      dataTablesParameters.search.value != ""
    ) {
      payload.searchText = dataTablesParameters.search.value;
    }
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/filter-enquiry-date?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=enquiryDate,desc",
        payload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public exportUserActivityLogs(authToken, payload): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseurl + "api/search-stats?page=0&size=100000",
        payload,
        {
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public exportEnquiries(authToken, page, size, payload): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/filter-enquiry-date?page=" +
        page +
        "&size=" +
        size +
        "&sort=enquiryDate,desc",
        payload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Enquiries
  public getEnquiryDetails(enqId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/enquiries/" + enqId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST User Data
  public postUserData(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/users-partial-update/",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public postUserDataTemp(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/sellers-partial-update-finish/",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putUserDataTemp(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/sellers-partial-update/",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //POST User Data
  public postFullUserData(
    data,
    authToken,
    createDummyProduct?
  ): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl +
        "api/users/?createDummyProduct=" +
        (createDummyProduct ? true : false),
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public addDoc(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/document-files",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public changePassword(passdata, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/account/change-password",
        JSON.stringify(passdata),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // public GetHomeBannerPage(data): Observable<Banner> {
  //   //console.log(JSON.stringify(data));
  //   return data;
  //   // return this.httpClient.post<LoginObserver>(this.baseurl + 'api/account/reset-password/init', data, { headers: new HttpHeaders().set('Content-Type', 'application/json')})
  //   //   .pipe(
  //   //     retry(1)
  //   //     // catchError(this.handleError)
  //   //   );
  // }

  //public getLatestProducts(): Observable<any> {
  //  return this.httpClient.get('assets/testingJson/latestProductJsonv2.json');
  //}

  public getCountries(): Observable<any> {
    return this.httpClient.get("assets/testingJson/countries_v4.json");
  }

  public getQuantityUnits(): Observable<any> {
    return this.httpClient.get("assets/testingJson/quantity_units.json");
  }

  public getUserInfo(email, authToken): Observable<any> {
    // console.log("Calling the USer Info API for ",email,"with token Id", authToken);
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/users/" + email, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getSellerUserInfo(email, authToken): Observable<any> {
    // console.log("Calling the USer Info API for ",email,"with token Id", authToken);
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/guest/seller-users/" + email, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getTempUserInfo(email, authToken): Observable<any> {
    // console.log("Calling the USer Info API for ",email,"with token Id", authToken);
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/temp-sellers/" + email, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductCategories(): Observable<any> {
    // return this.httpClient.get<any>(this.baseurl + 'api/guest/products-categories?page='+dataTablesParameters.start+'&size='+dataTablesParameters.length+'&sort=categoryName', { headers: new HttpHeaders().set('Content-Type', 'application/json')})
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/products-categories?size=" +
        1000 +
        "&sort=categoryName",
        { headers: new HttpHeaders().set("Content-Type", "application/json") }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductCategoriesList(dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/products-categories?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=categoryName" +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductCategoriesHeader(dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/products-categories?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=categoryName",
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductCategory(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/products-categories/" + catId,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Product Category
  public deleteProductCategories(catId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(
        this.baseurl + "api/products-categories/" + catId,
        { headers: headersForUserAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Product Category
  public addProductCategories(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/products-categories",
        JSON.stringify(catId),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updateProductCategories(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/products-categories/",
        JSON.stringify(catId),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductSubcategories(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl +
        "api/guest/products-subcategories/?size=2000&sort=subCategoryName",
        { headers: new HttpHeaders().set("Content-Type", "application/json") }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductSubcategoriesList(dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }

    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/products-subcategories/?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=subCategoryName" +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsers(authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/users?size=20000", httpOptions)
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getUniqueStates(authToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<any>(
        this.baseurl + "api/guest/unique-user-states?size=20000",
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getLoggedInUserData(authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<any>(this.baseurl + "api/account", httpOptions)
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersByUserType(userType, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/users-by-type/" +
        userType +
        "?size=10000&sort=createdDate,desc",
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersByUserTypeList(
    userType,
    authToken,
    dataTablesParameters
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };

    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/users-by-type/" +
        userType +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc" +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPartialUserBySub(subid, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<any>(
        this.baseurl + "api/partial-users-subscription/" + subid,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersPartial(authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<any>(
        this.baseurl + "api/partial-users?sort=sellerCompanyName,asc",
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersByUserTypeHeader(
    userType,
    authToken,
    dataTablesParameters
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/guest/users-by-type/" +
        userType +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public exportSellerData(payload, authToken, page, size): Observable<any> {
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/filter-seller-date?page=" + page + "&size=" + size,
        JSON.stringify(payload),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public filterEnquirySellers(payload, authToken, page, size): Observable<any> {
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl +
        "api/filter-sellers?page=" +
        page +
        "&size=" +
        size +
        "&sort=activePackageSubscription.subscriptionEndDate",
        JSON.stringify(payload),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public exportProductData(payload, authToken, page, size): Observable<any> {
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/filter-product-date?page=" + page + "&size=" + size,
        JSON.stringify(payload),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersTemp(authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };

    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/temp-sellers?size=2000",
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllUsersTempList(authToken, dataTablesParameters): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };

    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    // return this.httpClient.get<LoginObserver>(this.baseurl + 'api/guest/products-subcategories/?size=2000&sort=subCategoryName', { headers: new HttpHeaders().set('Content-Type', 'application/json') })
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/temp-sellers?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductSubCategory(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/products-subcategories/" + catId,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getEnquiryKeywords(payload, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/get-keywords",
        payload,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //Delete Product SubCategory
  public deleteProductSubCategories(catId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(
        this.baseurl + "api/products-subcategories/" + catId,
        { headers: headersForUserAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Product Category
  public addProductSubCategories(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/products-subcategories",
        JSON.stringify(catId),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updateProductSubCategories(catId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/products-subcategories",
        JSON.stringify(catId),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Package Feature
  public addPackageFeature(features, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/service-package-features",
        features,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackageFeatures(authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/service-package-features/",
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Package Feature
  public addPackage(packages, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/service-packages",
        JSON.stringify(packages),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updatePackage(packages, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/service-packages",
        JSON.stringify(packages),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackages(authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/service-packages", httpOptions)
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackagesById(packid, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/service-packages/" + packid,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deletePackage(packId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .delete<LoginObserver>(
        this.baseurl + "api/service-packages/" + packId,
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPopularProductCategories(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/featured-categories/", {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPopularProducts(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/top-products/", {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getLatestProducts(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/latest-products/", {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getLatestOffers(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/guest/offers", {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductSeach(token, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/search-products-all" +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&text=" +
        text +
        "&sort=categoryName",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductSeachTempNew(dataTablesParameters, token): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/search-temp-products/" +
        text +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getUserSeach(dataTablesParameters, token, type): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/search-users/" +
        text +
        "?userType=" +
        type +
        "&page=" +
        page +
        "&size=" +
        dataTablesParameters.length,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getUserSeachAll(text, token): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<any>(this.baseurl + "api/search-users/" + text + "?size=50", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductSeachAll(text, token): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<any>(this.baseurl + "api/search-products/" + text + "?size=50", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductSeachTemp(dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/temp-products/" +
        text +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=categoryName",
        {
          observe: "response",
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getImageContent(Id): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/images/" + Id, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getDocContent(Id, token): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/document-files/" + Id, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getFeaturedProducts(): Observable<any> {
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/featured-products/", {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductDetails(prodId): Observable<any> {
    let token = localStorage.getItem("userAccessToken");
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/products/" + prodId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getProductDetailsTemp(prodId, token): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/temp-products/" + prodId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Requirements
  public getRequirements(userId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/user-buyer-requirements/" + userId,
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Requirements
  public getAllRequirements(reqId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    if (reqId == "all") {
      return this.httpClient
        .get<LoginObserver>(
          this.baseurl +
          "api/buyer-requirements?size=2000&sort=createdDate,desc",
          { headers: headersForEnquiriesAPI }
        )
        .pipe(
          retry(1)
          // catchError(this.handleError)
        );
    } else {
      return this.httpClient
        .get<LoginObserver>(this.baseurl + "api/buyer-requirements/" + reqId, {
          headers: headersForEnquiriesAPI,
        })
        .pipe(
          retry(1)
          // catchError(this.handleError)
        );
    }
  }

  public getAllRequirementsHeaderTemp(
    authToken,
    dataTablesParameters
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/buyer-requirements?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public filterSalesEnquiry(
    authToken,
    dataTablesParameters,
    payload
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    if (
      dataTablesParameters &&
      dataTablesParameters.search &&
      dataTablesParameters.search.value &&
      dataTablesParameters.search.value != ""
    ) {
      payload.searchText = dataTablesParameters.search.value;
    }
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/filter-buyer-requirements-date?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        payload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public exportSalesEnquiries(authToken, page, size, payload): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/filter-buyer-requirements-date?page=" +
        page +
        "&size=" +
        size +
        "&sort=createdDate,desc",
        payload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // //GET Seller Requirements
  // public getSellerRequirements(authToken): Observable<LoginObserver> {
  //   var headersForEnquiriesAPI = new HttpHeaders().set('Authorization', 'Bearer ' + authToken);
  //   //console.log(headersForEnquiriesAPI);
  //   //console.log(JSON.stringify(data));
  //   return this.httpClient.get<LoginObserver>(this.baseurl + 'api/user-buyer-requirements', {headers: headersForEnquiriesAPI})
  //     .pipe(
  //       retry(1)
  //       // catchError(this.handleError)
  //     );
  // }

  // GET Seller Products
  public getSellerProducts(sellerId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/guest/products/by-seller/" + sellerId,
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Seller Requirements
  public getSellerRequirements(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(this.baseurl + "api/buyer-requirements", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //DELETE Requirements
  public deleteRequirements(reqId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/buyer-requirements/" + reqId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteGenEnq(reqId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/enquiries/" + reqId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //GET Browsing History
  public getBrowsingHistory(userId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    //console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/user-browsing-history/" + userId + "?size=1000",
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Add a Product

  public addProduct(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/products",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Delete a Product

  public deleteProduct(prodId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/products/" + prodId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteTempProduct(prodId, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(data));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/temp-products/" + prodId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Update a Product

  public updateProduct(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(this.baseurl + "api/products", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updateProductTemp(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/products-update-finish",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Add an Image

  public addImage(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(this.baseurl + "api/images", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public filterLeadCapture(
    authToken,
    filterPayload,
    page,
    size
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/filter-captured-leads?page=" +
        page +
        "&size=" +
        size +
        "&sort=createdDate,desc",
        filterPayload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }
  public getLeadCaptureFilters(authToken, filterPayload): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseurl + "api/guest/lead-capture-filters",
        filterPayload,
        {
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Add a Product

  public getAllProduct(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/products?size=10000&sort=createdDate,desc",
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductHeader(authToken, dataTablesParameters): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/products?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getAllProductHeaderTemp(
    authToken,
    dataTablesParameters
  ): Observable<any> {
    let page = dataTablesParameters.start;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/temp-products?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Role
  public addRole(role, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/roles",
        JSON.stringify(role),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Read Existing Roles
  public getRole(roleId, authToken): Observable<any> {
    // console.log("Role", roleId);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    if (roleId == "all") {
      return this.httpClient
        .get<LoginObserver>(this.baseurl + "api/roles", httpOptions)
        .pipe(
          retry(1)
          // catchError(this.handleError)
        );
    } else {
      return this.httpClient
        .get<LoginObserver>(this.baseurl + "api/roles/" + roleId, httpOptions)
        .pipe(
          retry(1)
          // catchError(this.handleError)
        );
    }
  }

  // Delete Existing Roles
  public deleteRole(roleId, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/roles/" + roleId, httpOptions)
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Update Existing Roles
  public updateRole(role, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/roles",
        JSON.stringify(role),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Add a User

  public addUser(
    user,
    authToken,
    createDummyProduct?
  ): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(user));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl +
        "api/register?createDummyProduct=" +
        (createDummyProduct ? true : false),
        JSON.stringify(user),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Delete a User

  public deleteUser(login, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/users/" + login, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deleteTempUser(id, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/temp-sellers/" + id, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public assignRole(role, override, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(role));
    //console.log("Lets override the roles");
    let flag = true;
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/users/assign-role/?override=" + flag,
        JSON.stringify(role),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // Create Package Subscription
  public addPackageSubscription(packSub, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/package-subscriptions",
        JSON.stringify(packSub),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public subscribeSellerToPack(sellerPack, authToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
    };
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/package-subscriptions/subscribe",
        JSON.stringify(sellerPack),
        httpOptions
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackageSubJobs(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/package-subscription-jobs?size=2000",
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackageSubJobsList(
    authToken,
    dataTablesParameters
  ): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }

    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/package-subscription-jobs?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + authToken)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobInfo(jobId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    // console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(this.baseurl + "api/package-subscription-jobs/" + jobId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public assignManager(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/package-subscription-jobs?size=2000",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public addPackageSubFeatureJob(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/package-subscription-feature-jobs",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sendMail(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(
        this.baseurl + "api/account/mail/send-mail",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sendBulkEnquiries(data, authToken, type): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    return this.httpClient
      .post<any>(
        this.baseurl + "api/send-bulk-enquiries?type=" + type,
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sendBulkRequirements(data, authToken, type): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    return this.httpClient
      .post<any>(
        this.baseurl + "api/send-bulk-requirements?type=" + type,
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public sendSms(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(
        this.baseurl + "api/otp/sms/send-message",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updatePackageSubFeatureJob(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<any>(
        this.baseurl + "api/package-subscription-feature-jobs",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobByUser(userId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(
        this.baseurl + "api/package-subscription-jobs-by-user/" + userId,
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getPackageFeatureSubJobs(authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    // console.log(headersForEnquiriesAPI);
    //console.log(JSON.stringify(data));
    return this.httpClient
      .get<LoginObserver>(
        this.baseurl + "api/package-subscription-feature-jobs?size=2000",
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getFeatureJobByUser(userId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/package-subscription-feature-jobs-by-user/" +
        userId,
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getFeatureJobInfo(jobId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(
        this.baseurl + "api/package-subscription-feature-jobs/" + jobId,
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public updateFeatureJobStatus(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<LoginObserver>(
        this.baseurl + "api/package-subscription-feature-jobs",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  //get all  country code json
  public getMobileOtpCountryCodes(): Observable<any> {
    return this.httpClient.get("assets/json_files/countryCodes.json");
  }

  // job portal api
  public postJob(data, authToken): Observable<LoginObserver> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<LoginObserver>(
        this.baseurl + "api/job-portals",
        JSON.stringify(data),
        { headers: headersForEnquiriesAPI }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobCategory(dataTablesParameters, token): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/job-categories?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobAll(dataTablesParameters, token): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .get<any>(
        this.baseurl +
        "api/job-portals?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : "") +
        "&sort=createdDate,desc",
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }
  public getJobApplicants(
    dataTablesParameters,
    payload,
    token,
    jobId
  ): Observable<any> {
    let page = dataTablesParameters.start;
    let text = dataTablesParameters.search.value;
    if (page != 0) {
      page = dataTablesParameters.start / dataTablesParameters.length;
    }
    return this.httpClient
      .post<any>(
        this.baseurl +
        "api/job-applicants-by-job/" +
        jobId +
        "?page=" +
        page +
        "&size=" +
        dataTablesParameters.length +
        (text ? "&searchText=" + text : ""),
        payload,
        {
          observe: "response",
          headers: new HttpHeaders()
            .set("Authorization", "Bearer " + token)
            .set("Content-Type", "application/json"),
        }
      )
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobData(token): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.httpClient
      .get<any>(this.baseurl + "api/job-categories", {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobApplicantsId(jobId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(this.baseurl + "api/job-applicants/" + jobId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putJobApplicants(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<any>(this.baseurl + "api/job-applicants", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobById(jobId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(this.baseurl + "api/job-portals/" + jobId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putJob(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .put<any>(this.baseurl + "api/job-portals", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deletejob(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/job-portals/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public addjobCategory(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    console.log(JSON.stringify(data));
    return this.httpClient
      .post<any>(this.baseurl + "api/job-categories", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public putJobCategory(data, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    return this.httpClient
      .put<any>(this.baseurl + "api/job-categories", JSON.stringify(data), {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public deletejobCategory(offerId, authToken): Observable<any> {
    var headersForUserAPI = new HttpHeaders().set(
      "Authorization",
      "Bearer " + authToken
    );
    return this.httpClient
      .delete<LoginObserver>(this.baseurl + "api/job-categories/" + offerId, {
        headers: headersForUserAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  public getJobCategoryId(jobId, authToken): Observable<any> {
    var headersForEnquiriesAPI = new HttpHeaders()
      .set("Authorization", "Bearer " + authToken)
      .set("Content-Type", "application/json");
    console.log(headersForEnquiriesAPI);
    // console.log(JSON.stringify(user));
    return this.httpClient
      .get<any>(this.baseurl + "api/job-categories/" + jobId, {
        headers: headersForEnquiriesAPI,
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }
}
